var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ACRUDTable',{ref:"crud_table",attrs:{"fields":_vm.fields || [
        //'id',
        _vm.helpers.monthName,
        { key: 'month_id', _classes: 'hide' },
        _vm.helpers.identifierText,
        _vm.helpers.ownerNameText,
        { key: 'consecutive', label: '# Current invoice', helper: { id: 'invoice.consecutive' } } ].concat( _vm.dynamic_fields
      ),"config":Object.assign({}, {name: 'invoice',
      url: 'building_balance' + (this.current_building_id ? ("?building_id=" + (this.current_building_id)) : ''),
      //route: '/no-users/building_balance',
      display_name: 'Estado de cuenta inmueble',
      params: Object.assign({}, {_lists: 'months,buildings,contact_types,building_types,property_units', building_id: this.building_id || 0}, (_vm.params || {})),
      /*filters: filters
        ? [
            //{ key: 'month_id', default: 'response.data.month_ids', multiple: true, forced: true, list: 'months', callback: this.setMonths, buttonCallback: this.onGetData, config: { select_all: true } },
            { key: 'building_code', default: 'response.data.building_id', multiple: false, config: { label: 'identifier' }, forced: true, list: 'buildings', callback: this.setBuilding }
          ]
        : [],*/
      noCreate: true,
      noOptions: 'hide',
      parseItems: _vm.parseItems},
      (_vm.config || {})),"email":_vm.modalEmail,"sorterValue":{ column: 'consecutive', asc: false }},on:{"preparedHelpers":_vm.onPreparedHelpers}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }