<template>
  <div>
    <ACRUDTable
      ref="crud_table"
      :fields="
        fields || [
          //'id',
          helpers.monthName,
          { key: 'month_id', _classes: 'hide' },
          helpers.identifierText,
          helpers.ownerNameText,
          { key: 'consecutive', label: '# Current invoice', helper: { id: 'invoice.consecutive' } },
          ...dynamic_fields
        ]
      "
      :config="{
        name: 'invoice',
        url: 'building_balance' + (this.current_building_id ? `?building_id=${this.current_building_id}` : ''),
        //route: '/no-users/building_balance',
        display_name: 'Estado de cuenta inmueble',
        params: { _lists: 'months,buildings,contact_types,building_types,property_units', building_id: this.building_id || 0, ...(params || {}) },
        /*filters: filters
          ? [
              //{ key: 'month_id', default: 'response.data.month_ids', multiple: true, forced: true, list: 'months', callback: this.setMonths, buttonCallback: this.onGetData, config: { select_all: true } },
              { key: 'building_code', default: 'response.data.building_id', multiple: false, config: { label: 'identifier' }, forced: true, list: 'buildings', callback: this.setBuilding }
            ]
          : [],*/
        noCreate: true,
        noOptions: 'hide',
        parseItems,
        ...(config || {})
      }"
      :email="modalEmail"
      :sorterValue="{ column: 'consecutive', asc: false }"
      @preparedHelpers="onPreparedHelpers"
    >
      <!--template #header-button>
        <CButton type="button" class="float-right mr-2" color="danger" @click="onEmailInvoice"><CIcon name="cil-envelope-closed"/></CButton>
      </template-->
    </ACRUDTable>
  </div>
</template>

<script>
import crudMixin from '@/app/views/_mixins/crud-mixin'

export default {
  name: 'NU-BuildingBalance',
  mixins: [crudMixin],
  props: ['building_id', 'fields', 'filters', 'config', 'params'],
  watch: {
    building_id(new_value, old_value) {
      if (new_value && new_value !== old_value) this.onGetData(new_value)
    }
  },
  data() {
    return {
      loading: false,
      month_ids: '',
      dynamic_fields: [],
      current_building_id: this.building_id || 0,
      modalEmail: {
        show: false,
        button: true,
        prefix: '',
        invoice: false,
        recipients: [],
        onEmail: this.onEmailInvoice
      }
    }
  },
  methods: {
    setMonths(month_ids) {
      this.month_ids = month_ids
    },
    setBuilding(building_id) {
      this.current_building_id = building_id
      this.onGetData()
    },

    onEmailInvoice(modalEmail) {
      modalEmail.prefix = this.$t('Building balance')

      modalEmail.recipients = [
        { name: 'Send copy to', emails: [{ email: '', checked: false }], type: 'others' },
        { name: 'Admon copy', emails: [{ email: this.property.email, checked: false }], readonly: true, type: 'others' },
        { name: 'Copy me', emails: [{ email: this.loggedUser.email, checked: false }], readonly: true, type: 'others' }
      ]

      const building = this.$refs.crud_table.response.data.building
      // TODO: order by type_id ??
      for (let contact in building.contacts.filter(contact => contact.type_id && contact.type_id < 3)) {
        contact = building.contacts[contact]
        contact.contact.emails.forEach(email => {
          email.checked = false
        })
        modalEmail.recipients.push({ ...contact, readonly: true, type: contact.type_id === 1 ? 'to' : 'cc', checked: false, emails: contact.contact.emails })
      }

      modalEmail.entity = { id: building.id, type: 'building', origin: 'building_balance', subject: building.identifier, file_name: `${modalEmail.prefix} - ${building.identifier}.xlsx`, file_type: 'xlsx' }
      modalEmail.contact_types = this.view_helpers.contact_types
    },

    onGetData(building_id) {
      // TODO: grab month_ids and building_id from the input ??
      this.$refs.crud_table.getData(false, { building_id }, true)
      /*if (this.month_ids.length && this.current_building_id) {
        this.loading = true
        this.$refs.crud_table.getData(false, { building_id: this.current_building_id, month_ids: this.month_ids }, true)
      }*/
    },
    parseItems(parent, items) {
      this.current_building_id = this.$refs.crud_table.response.data.building_id
      this.month_ids = this.$refs.crud_table.response.data.month_ids
      this.loading = false

      this.$set(this.$refs.crud_table.options, 'file_name', this.property.name + ' - ' + 'Estado de cuenta Inmueble - ' + this.parsers.readHelper(this.current_building_id, 'buildings', 'identifier'))

      this.dynamic_fields = []
      const dynamic_fields = {
        overdue_values: {},
        overdue_notes: {},
        current_values: {},
        current_notes: {}
      }
      for (let item in items) {
        item = items[item]
        item.db_notes = 0
        item.cr_notes = 0
        item.admon_value = 0
        item.overdue_capital = 0

        // TODO: common function with Detailed ???
        for (const overdue_value of item.overdue_values) {
          dynamic_fields.overdue_values[overdue_value.name] = overdue_value.name
          item.overdue_capital += overdue_value.value
          item['(V) ' + overdue_value.name] = overdue_value.value
        }
        for (const overdue_note of item.overdue_notes) {
          dynamic_fields.overdue_notes[overdue_note.name] = overdue_note.name
          if (overdue_note.nature_id === 'DB') item.db_notes += overdue_note.value
          else item.cr_notes += overdue_note.value
          item['(NV) ' + overdue_note.name] = overdue_note.value
        }
        for (const current_value of item.current_values) {
          // TODO: admon imputation classes as Params?
          if (this.is_summary && ['AAP', 'APQ', 'ACU'].indexOf(current_value.imputation_class_id) >= 0) {
            item.admon_value += current_value.value
          } else {
            dynamic_fields.current_values[current_value.name] = current_value.name
          }
          item[current_value.name] = current_value.value
        }
        for (const current_note of item.current_notes) {
          dynamic_fields.current_notes[current_note.name] = current_note.name
          if (current_note.nature_id === 'DB') item.db_notes += current_note.value
          else item.cr_notes += current_note.value
          item['(N) ' + current_note.name] = current_note.value
        }
      }

      // Dynamic Fields
      this.applyOrdering(dynamic_fields.overdue_values, '(V) ', 'background-color: #1769ff;') // TODO: use variables
      this.dynamic_fields.push({ ...this.helpers.currencyValue, key: 'overdue_interest', _style: 'background-color: #1769ff;' })
      this.applyOrdering(dynamic_fields.overdue_notes, '(NV) ', 'background-color: #3c81ff;')
      this.dynamic_fields.push({ ...this.helpers.currencyValue, key: 'generated_interest', _style: 'background-color: #45a164;' })
      this.dynamic_fields.push({ key: 'interest', _classes: 'text-right', formatter: 'numeric', render: value => this.parsers.numeric(value) + ' %', label: '% Interest', _style: 'background-color: #45a164;' })
      this.applyOrdering(dynamic_fields.current_values, '', 'background-color: #45a164;')
      this.applyOrdering(dynamic_fields.current_notes, '(N) ', 'background-color: #74a383;')
      this.dynamic_fields.push({ ...this.helpers.currencyValue, key: 'total_balance' })
      this.dynamic_fields.push({ ...this.helpers.currencyValue, key: 'current_month_payment' })
      this.dynamic_fields.push({ ...this.helpers.currencyValue, key: 'total_balance_final' })

      return items
    },

    applyOrdering(dynamic_fields, prefix, _style) {
      const dynamic_fields_array = Object.keys(dynamic_fields)
      for (const imputation of this.$refs.crud_table.response.data.imputation_order) {
        if (dynamic_fields_array.indexOf(imputation.name) >= 0) this.dynamic_fields.push({ ...this.helpers.currencyValue, key: (prefix || '') + imputation.name, label: (prefix || '') + imputation.name, _style }) // TODO: Capitalize ?
      }
    }
  }
}
</script>
